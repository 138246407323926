body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  overflow-y: hidden;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

#root {
  overflow: hidden;
}

#sticky {
  position: sticky;
  position: -webkit-sticky;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow: hidden;
  }

  #main-panel {
    -webkit-overflow-scrolling: touch;
  }
}
